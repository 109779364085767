import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AxApiBaseService } from 'src/app/ax-commons-api/ax-api-base.service';
import { AxApiGetColorForStatusPipe } from 'src/app/ax-commons-api/ax-api-get-color-for-status.pipe';
import { AxApiGetIconForStatusPipe } from 'src/app/ax-commons-api/ax-api-get-icon-for-status.pipe';
import { AxApiGetIconForTimerecordType } from 'src/app/ax-commons-api/ax-api-get-icon-for-timerecord-type.pipe';
import { AxApiGetUploadPipe } from 'src/app/ax-commons-api/ax-api-get-upload.pipe';
import { AxApiJsonInterceptor } from 'src/app/ax-commons-api/ax-api-json.interceptor';
import { AxApiGetUrlFromFullPath } from 'src/app/ax-commons-api/ax-url-from-full-path.pipe';
import { AxApiGetDetailIconForEventInvitationPipe } from './ax-api-get-detail-icon-for-event-invitation.pipe';

@NgModule({
  providers: [
    AxApiBaseService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AxApiJsonInterceptor,
      multi: true,
    },
  ],
  declarations: [
    AxApiGetUploadPipe,
    AxApiGetColorForStatusPipe,
    AxApiGetIconForStatusPipe,
    AxApiGetIconForTimerecordType,
    AxApiGetDetailIconForEventInvitationPipe,
    AxApiGetUrlFromFullPath,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: [
    AxApiGetUploadPipe,
    AxApiGetColorForStatusPipe,
    AxApiGetIconForStatusPipe,
    AxApiGetIconForTimerecordType,
    AxApiGetDetailIconForEventInvitationPipe,
    AxApiGetUrlFromFullPath,
  ],
})
export class AxCommonsApiModule {
}
