/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxGeruestbauProjectDto } from '../models/ax-geruestbau-project-dto';
import { AxTask } from '../models/ax-task';
import { AxUpdateTaskDto } from '../models/ax-update-task-dto';

@Injectable({ providedIn: 'root' })
export class AxGerustbauV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `geruestbauControllerGetProjectsByInstallationStartDaterange()` */
  static readonly GeruestbauControllerGetProjectsByInstallationStartDaterangePath = '/v2/projects/geruestbau/projects/byInstallationStartDaterange/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geruestbauControllerGetProjectsByInstallationStartDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerGetProjectsByInstallationStartDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
      businessunitId?: number;
      overdue?: boolean;
      abbauNotPlanned?: boolean;
      geplantOrFreigegeben?: boolean;
      sortByGeruestaufbau?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxGeruestbauProjectDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxGerustbauV2Service.GeruestbauControllerGetProjectsByInstallationStartDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.query('businessunitId', params.businessunitId, {});
      rb.query('overdue', params.overdue, {});
      rb.query('abbauNotPlanned', params.abbauNotPlanned, {});
      rb.query('geplantOrFreigegeben', params.geplantOrFreigegeben, {});
      rb.query('sortByGeruestaufbau', params.sortByGeruestaufbau, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxGeruestbauProjectDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geruestbauControllerGetProjectsByInstallationStartDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerGetProjectsByInstallationStartDaterange(
    params: {
      fromDate: string;
      toDate: string;
      businessunitId?: number;
      overdue?: boolean;
      abbauNotPlanned?: boolean;
      geplantOrFreigegeben?: boolean;
      sortByGeruestaufbau?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<AxGeruestbauProjectDto>> {
    return this.geruestbauControllerGetProjectsByInstallationStartDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxGeruestbauProjectDto>>): Array<AxGeruestbauProjectDto> => r.body)
    );
  }

  /** Path part for operation `geruestbauControllerApproveGeruestabbau()` */
  static readonly GeruestbauControllerApproveGeruestabbauPath = '/v2/projects/geruestbau/projects/{id}/approveGeruestabbau';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geruestbauControllerApproveGeruestabbau()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerApproveGeruestabbau$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxGerustbauV2Service.GeruestbauControllerApproveGeruestabbauPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geruestbauControllerApproveGeruestabbau$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerApproveGeruestabbau(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.geruestbauControllerApproveGeruestabbau$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `geruestbauControllerUpdateTask()` */
  static readonly GeruestbauControllerUpdateTaskPath = '/v2/projects/geruestbau/tasks/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geruestbauControllerUpdateTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  geruestbauControllerUpdateTask$Response(
    params: {
      id: number;
      body: AxUpdateTaskDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxGerustbauV2Service.GeruestbauControllerUpdateTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geruestbauControllerUpdateTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  geruestbauControllerUpdateTask(
    params: {
      id: number;
      body: AxUpdateTaskDto
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.geruestbauControllerUpdateTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `geruestbauControllerAcceptTask()` */
  static readonly GeruestbauControllerAcceptTaskPath = '/v2/projects/geruestbau/tasks/{id}/accept';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geruestbauControllerAcceptTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerAcceptTask$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxGerustbauV2Service.GeruestbauControllerAcceptTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geruestbauControllerAcceptTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerAcceptTask(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.geruestbauControllerAcceptTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `geruestbauControllerDeclineTask()` */
  static readonly GeruestbauControllerDeclineTaskPath = '/v2/projects/geruestbau/tasks/{id}/decline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geruestbauControllerDeclineTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerDeclineTask$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxGerustbauV2Service.GeruestbauControllerDeclineTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geruestbauControllerDeclineTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerDeclineTask(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.geruestbauControllerDeclineTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `geruestbauControllerCompleteTask()` */
  static readonly GeruestbauControllerCompleteTaskPath = '/v2/projects/geruestbau/tasks/{id}/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geruestbauControllerCompleteTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerCompleteTask$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxGerustbauV2Service.GeruestbauControllerCompleteTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geruestbauControllerCompleteTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerCompleteTask(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.geruestbauControllerCompleteTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `geruestbauControllerAssignTaskToTeam()` */
  static readonly GeruestbauControllerAssignTaskToTeamPath = '/v2/projects/geruestbau/tasks/{id}/team/{teamId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geruestbauControllerAssignTaskToTeam()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerAssignTaskToTeam$Response(
    params: {
      id: number;
      teamId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxTask>> {
    const rb = new RequestBuilder(this.rootUrl, AxGerustbauV2Service.GeruestbauControllerAssignTaskToTeamPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('teamId', params.teamId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxTask>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geruestbauControllerAssignTaskToTeam$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerAssignTaskToTeam(
    params: {
      id: number;
      teamId: number;
    },
    context?: HttpContext
  ): Observable<AxTask> {
    return this.geruestbauControllerAssignTaskToTeam$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxTask>): AxTask => r.body)
    );
  }

  /** Path part for operation `geruestbauControllerPrepareGeruestbauInfoPdfForProject()` */
  static readonly GeruestbauControllerPrepareGeruestbauInfoPdfForProjectPath = '/v2/projects/geruestbau/prepareGeruestbauInfoPdfForProject/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `geruestbauControllerPrepareGeruestbauInfoPdfForProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerPrepareGeruestbauInfoPdfForProject$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AxGerustbauV2Service.GeruestbauControllerPrepareGeruestbauInfoPdfForProjectPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `geruestbauControllerPrepareGeruestbauInfoPdfForProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  geruestbauControllerPrepareGeruestbauInfoPdfForProject(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.geruestbauControllerPrepareGeruestbauInfoPdfForProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
