import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AxCommonsApiModule } from 'src/app/ax-commons-api/ax-commons-api.module';
import { AxAppointmentProjectOverviewComponent } from 'src/app/components/ax-appointment-project-overview/ax-appointment-project-overview.component';
import { AxAppointmentComponent } from 'src/app/components/ax-appointment/ax-appointment.component';
import { AxCommentComponent } from 'src/app/components/ax-comment/ax-comment.component';
import { AxEventInvitationItemComponent } from 'src/app/components/ax-event-invitation-item/ax-event-invitation-item.component';
import { AxEventItemComponent } from 'src/app/components/ax-event-item/ax-event-item.component';
import { AxItemDetailsComponent } from 'src/app/components/ax-item-details/ax-item-details.component';
import { AxNewsItemComponent } from 'src/app/components/ax-news-item/ax-news-item.component';
import { AxPersonInfoboxComponent } from 'src/app/components/ax-person-infobox/ax-person-infobox.component';
import { AxProjectSelectorComponent } from 'src/app/components/ax-project-selector/ax-project-selector.component';
import { AxProjectComponent } from 'src/app/components/ax-project/ax-project.component';
import { AxSecuredImageComponent } from 'src/app/components/ax-secured-image/ax-secured-image.component';
import { AxSegmentsComponent } from './ax-segments/ax-segments.component';
import { AxSunnyComponent } from './ax-sunny/ax-sunny.component';
import { PipesModule } from '../pipes/pipes.module';
import { AxProjectIntroComponent } from './ax-project-intro/ax-project-intro.component';
import { AxProjectContactPersonsComponent } from './ax-project-contact-persons/ax-project-contact-persons.component';
import { AxProjectAppointmentsComponent } from './ax-project-appointments/ax-project-appointments.component';
import { AxProjectProjectrevenuesComponent } from './ax-project-projectrevenues/ax-project-projectrevenues.component';
import { AxProjectItemsForCustomersComponent } from './ax-project-items-for-customers/ax-project-items-for-customers.component';
import { AxProjectElectricalMeasurementsComponent } from './ax-project-electrical-measurements/ax-project-electrical-measurements.component';
import { AxProjectItemsForEmployeesComponent } from './ax-project-items-for-employees/ax-project-items-for-employees.component';
import { AxProjectBaustellenDispoComponent } from './ax-project-baustellen-dispo/ax-project-baustellen-dispo.component';
import { AxTimerecorddetailsListComponent } from './ax-timerecorddetails-list/ax-timerecorddetails-list.component';
import { RouterLink } from '@angular/router';
import { AxTagesuebersichtCardComponent } from './ax-tagesuebersicht-card/ax-tagesuebersicht-card.component';
import { AxProjectDocumentsForEmployeesComponent } from './ax-project-documents-for-employees/ax-project-documents-for-employees.component';

@NgModule({
  declarations: [
    AxAppointmentComponent,
    AxAppointmentProjectOverviewComponent,
    AxCommentComponent,
    AxEventInvitationItemComponent,
    AxEventItemComponent,
    AxItemDetailsComponent,
    AxNewsItemComponent,
    AxPersonInfoboxComponent,
    AxProjectAppointmentsComponent,
    AxProjectBaustellenDispoComponent,
    AxProjectComponent,
    AxProjectContactPersonsComponent,
    AxProjectElectricalMeasurementsComponent,
    AxProjectIntroComponent,
    AxProjectItemsForCustomersComponent,
    AxProjectItemsForEmployeesComponent,
    AxProjectProjectrevenuesComponent,
    AxProjectSelectorComponent,
    AxSecuredImageComponent,
    AxSegmentsComponent,
    AxSunnyComponent,
    AxTagesuebersichtCardComponent,
    AxTimerecorddetailsListComponent,
    AxProjectDocumentsForEmployeesComponent,
  ],
  imports: [
    AxCommonsApiModule,
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    RouterLink,
  ],
  exports: [
    AxAppointmentComponent,
    AxAppointmentProjectOverviewComponent,
    AxCommentComponent,
    AxEventInvitationItemComponent,
    AxEventItemComponent,
    AxItemDetailsComponent,
    AxNewsItemComponent,
    AxPersonInfoboxComponent,
    AxProjectComponent,
    AxProjectSelectorComponent,
    AxSecuredImageComponent,
    AxSegmentsComponent,
    AxSunnyComponent,
    AxTagesuebersichtCardComponent,
    AxTimerecorddetailsListComponent,
    AxProjectDocumentsForEmployeesComponent,
  ],
})
export class AxComponentsModule {
}
