/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxBuildsByContainerIdYearAndMonthDto } from '../models/ax-builds-by-container-id-year-and-month-dto';
import { AxCalculationitem } from '../models/ax-calculationitem';
import { AxDebtor } from '../models/ax-debtor';
import { AxKrankheitUnfallTageDto } from '../models/ax-krankheit-unfall-tage-dto';
import { AxRevenueByContainerIdYearAndMonthDto } from '../models/ax-revenue-by-container-id-year-and-month-dto';
import { AxSalesForPvDto } from '../models/ax-sales-for-pv-dto';
import { AxSpesentageDto } from '../models/ax-spesentage-dto';
import { AxTimerecordBalancesByYearMonthAndUserIdDto } from '../models/ax-timerecord-balances-by-year-month-and-user-id-dto';
import { AxTransformedBankFileDto } from '../models/ax-transformed-bank-file-dto';
import { AxUploadBankFileDto } from '../models/ax-upload-bank-file-dto';
import { AxUseremployment } from '../models/ax-useremployment';
import { AxUseremploymentTypeEnum } from '../models/ax-useremployment-type-enum';
import { AxWegspesenDto } from '../models/ax-wegspesen-dto';

@Injectable({ providedIn: 'root' })
export class AxReportsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `reportsControllerFindSalesByContainerIdYearAndMonth()` */
  static readonly ReportsControllerFindSalesByContainerIdYearAndMonthPath = '/v2/reports/sales/{containertypeId}/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindSalesByContainerIdYearAndMonth()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindSalesByContainerIdYearAndMonth$Response(
    params: {
      containertypeId: number;
      year: string;
      month: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxRevenueByContainerIdYearAndMonthDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindSalesByContainerIdYearAndMonthPath, 'get');
    if (params) {
      rb.path('containertypeId', params.containertypeId, {});
      rb.path('year', params.year, {});
      rb.path('month', params.month, {});
      rb.query('withDetails', params.withDetails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxRevenueByContainerIdYearAndMonthDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindSalesByContainerIdYearAndMonth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindSalesByContainerIdYearAndMonth(
    params: {
      containertypeId: number;
      year: string;
      month: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<AxRevenueByContainerIdYearAndMonthDto> {
    return this.reportsControllerFindSalesByContainerIdYearAndMonth$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxRevenueByContainerIdYearAndMonthDto>): AxRevenueByContainerIdYearAndMonthDto => r.body)
    );
  }

  /** Path part for operation `reportsControllerFindSalesForPv()` */
  static readonly ReportsControllerFindSalesForPvPath = '/v2/reports/salesForPv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindSalesForPv()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindSalesForPv$Response(
    params?: {
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSalesForPvDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindSalesForPvPath, 'get');
    if (params) {
      rb.query('withDetails', params.withDetails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSalesForPvDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindSalesForPv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindSalesForPv(
    params?: {
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<AxSalesForPvDto> {
    return this.reportsControllerFindSalesForPv$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSalesForPvDto>): AxSalesForPvDto => r.body)
    );
  }

  /** Path part for operation `reportsControllerFindBuildsByContainerIdYearAndMonth()` */
  static readonly ReportsControllerFindBuildsByContainerIdYearAndMonthPath = '/v2/reports/builds/{containertypeId}/{year}/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindBuildsByContainerIdYearAndMonth()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindBuildsByContainerIdYearAndMonth$Response(
    params: {
      containertypeId: number;
      year: string;
      month: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxBuildsByContainerIdYearAndMonthDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindBuildsByContainerIdYearAndMonthPath, 'get');
    if (params) {
      rb.path('containertypeId', params.containertypeId, {});
      rb.path('year', params.year, {});
      rb.path('month', params.month, {});
      rb.query('withDetails', params.withDetails, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxBuildsByContainerIdYearAndMonthDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindBuildsByContainerIdYearAndMonth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindBuildsByContainerIdYearAndMonth(
    params: {
      containertypeId: number;
      year: string;
      month: string;
      withDetails?: boolean;
    },
    context?: HttpContext
  ): Observable<AxBuildsByContainerIdYearAndMonthDto> {
    return this.reportsControllerFindBuildsByContainerIdYearAndMonth$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxBuildsByContainerIdYearAndMonthDto>): AxBuildsByContainerIdYearAndMonthDto => r.body)
    );
  }

  /** Path part for operation `reportsControllerFindCalcutationitemsByStocklocationId()` */
  static readonly ReportsControllerFindCalcutationitemsByStocklocationIdPath = '/v2/reports/calculationitems/byStocklocationId/{stocklocationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindCalcutationitemsByStocklocationId()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindCalcutationitemsByStocklocationId$Response(
    params: {
      stocklocationId: number;
      deliveredAt?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxCalculationitem>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindCalcutationitemsByStocklocationIdPath, 'get');
    if (params) {
      rb.path('stocklocationId', params.stocklocationId, {});
      rb.query('deliveredAt', params.deliveredAt, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxCalculationitem>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindCalcutationitemsByStocklocationId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindCalcutationitemsByStocklocationId(
    params: {
      stocklocationId: number;
      deliveredAt?: string;
    },
    context?: HttpContext
  ): Observable<Array<AxCalculationitem>> {
    return this.reportsControllerFindCalcutationitemsByStocklocationId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxCalculationitem>>): Array<AxCalculationitem> => r.body)
    );
  }

  /** Path part for operation `reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId()` */
  static readonly ReportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserIdPath = '/v2/reports/monthly-timerecord-balances-for-daterange/{fromDate}/{toDate}/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId$Response(
    params: {
      fromDate: string;
      toDate: string;
      userId: number;
      subtotalDate?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTimerecordBalancesByYearMonthAndUserIdDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserIdPath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
      rb.path('userId', params.userId, {});
      rb.query('subtotalDate', params.subtotalDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTimerecordBalancesByYearMonthAndUserIdDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId(
    params: {
      fromDate: string;
      toDate: string;
      userId: number;
      subtotalDate?: string;
    },
    context?: HttpContext
  ): Observable<Array<AxTimerecordBalancesByYearMonthAndUserIdDto>> {
    return this.reportsControllerFindMonthlyTimerecordBalancesByDaterangeAndUserId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTimerecordBalancesByYearMonthAndUserIdDto>>): Array<AxTimerecordBalancesByYearMonthAndUserIdDto> => r.body)
    );
  }

  /** Path part for operation `reportsLohnlaufControllerFindSpesentageForDaterange()` */
  static readonly ReportsLohnlaufControllerFindSpesentageForDaterangePath = '/v2/reports/lohnlauf/spesentage/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsLohnlaufControllerFindSpesentageForDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindSpesentageForDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxSpesentageDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsLohnlaufControllerFindSpesentageForDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxSpesentageDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsLohnlaufControllerFindSpesentageForDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindSpesentageForDaterange(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxSpesentageDto>> {
    return this.reportsLohnlaufControllerFindSpesentageForDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxSpesentageDto>>): Array<AxSpesentageDto> => r.body)
    );
  }

  /** Path part for operation `reportsLohnlaufControllerFindWegspesenForDaterange()` */
  static readonly ReportsLohnlaufControllerFindWegspesenForDaterangePath = '/v2/reports/lohnlauf/wegspesen/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsLohnlaufControllerFindWegspesenForDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindWegspesenForDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxWegspesenDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsLohnlaufControllerFindWegspesenForDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxWegspesenDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsLohnlaufControllerFindWegspesenForDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindWegspesenForDaterange(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxWegspesenDto>> {
    return this.reportsLohnlaufControllerFindWegspesenForDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxWegspesenDto>>): Array<AxWegspesenDto> => r.body)
    );
  }

  /** Path part for operation `reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange()` */
  static readonly ReportsLohnlaufControllerFindKrankheitUnfallTageForDaterangePath = '/v2/reports/lohnlauf/krankheitunfalltage/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxKrankheitUnfallTageDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsLohnlaufControllerFindKrankheitUnfallTageForDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxKrankheitUnfallTageDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxKrankheitUnfallTageDto>> {
    return this.reportsLohnlaufControllerFindKrankheitUnfallTageForDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxKrankheitUnfallTageDto>>): Array<AxKrankheitUnfallTageDto> => r.body)
    );
  }

  /** Path part for operation `reportsLohnlaufControllerFindChangedUseremploymentsForMonth()` */
  static readonly ReportsLohnlaufControllerFindChangedUseremploymentsForMonthPath = '/v2/reports/lohnlauf/changed-useremployments/{month}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsLohnlaufControllerFindChangedUseremploymentsForMonth()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindChangedUseremploymentsForMonth$Response(
    params: {
      useremploymentType: AxUseremploymentTypeEnum;
      month: number;
      entriesOnly?: boolean;
      exitsOnly?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxUseremployment>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsLohnlaufControllerFindChangedUseremploymentsForMonthPath, 'get');
    if (params) {
      rb.query('useremploymentType', params.useremploymentType, {});
      rb.path('month', params.month, {});
      rb.query('entriesOnly', params.entriesOnly, {});
      rb.query('exitsOnly', params.exitsOnly, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxUseremployment>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsLohnlaufControllerFindChangedUseremploymentsForMonth$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsLohnlaufControllerFindChangedUseremploymentsForMonth(
    params: {
      useremploymentType: AxUseremploymentTypeEnum;
      month: number;
      entriesOnly?: boolean;
      exitsOnly?: boolean;
    },
    context?: HttpContext
  ): Observable<Array<AxUseremployment>> {
    return this.reportsLohnlaufControllerFindChangedUseremploymentsForMonth$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxUseremployment>>): Array<AxUseremployment> => r.body)
    );
  }

  /** Path part for operation `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange()` */
  static readonly ReportsDebitorenabgleichControllerFindDebtorsPaidByDaterangePath = '/v2/reports/debitorenabgleich/debtors-paid/{fromDate}/{toDate}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxDebtor>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsDebitorenabgleichControllerFindDebtorsPaidByDaterangePath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxDebtor>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<Array<AxDebtor>> {
    return this.reportsDebitorenabgleichControllerFindDebtorsPaidByDaterange$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxDebtor>>): Array<AxDebtor> => r.body)
    );
  }

  /** Path part for operation `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv()` */
  static readonly ReportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsvPath = '/v2/reports/debitorenabgleich/debtors-paid/{fromDate}/{toDate}/csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv$Response(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsvPath, 'get');
    if (params) {
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv(
    params: {
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.reportsDebitorenabgleichControllerFindDebtorsPaidByDaterangeCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reportsDebitorenabgleichControllerUploadDebtorsBankImport()` */
  static readonly ReportsDebitorenabgleichControllerUploadDebtorsBankImportPath = '/v2/reports/debitorenabgleich/debtors-bank-import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsDebitorenabgleichControllerUploadDebtorsBankImport()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  reportsDebitorenabgleichControllerUploadDebtorsBankImport$Response(
    params: {
      body: AxUploadBankFileDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxTransformedBankFileDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReportsV2Service.ReportsDebitorenabgleichControllerUploadDebtorsBankImportPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxTransformedBankFileDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsDebitorenabgleichControllerUploadDebtorsBankImport$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  reportsDebitorenabgleichControllerUploadDebtorsBankImport(
    params: {
      body: AxUploadBankFileDto
    },
    context?: HttpContext
  ): Observable<Array<AxTransformedBankFileDto>> {
    return this.reportsDebitorenabgleichControllerUploadDebtorsBankImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxTransformedBankFileDto>>): Array<AxTransformedBankFileDto> => r.body)
    );
  }

}
