/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AxApiService } from './services/ax-api.service';
import { AxUseremploymentsV2Service } from './services/ax-useremployments-v-2.service';
import { AxMontageplanV2Service } from './services/ax-montageplan-v-2.service';
import { AxGerustbauV2Service } from './services/ax-gerustbau-v-2.service';
import { AxElektroDrittkontrolleV2Service } from './services/ax-elektro-drittkontrolle-v-2.service';
import { AxDocumentsGeneratorService } from './services/ax-documents-generator.service';
import { AxUsersService } from './services/ax-users.service';
import { AxPhonesV2Service } from './services/ax-phones-v-2.service';
import { AxNotificationsV2Service } from './services/ax-notifications-v-2.service';
import { AxHelpersService } from './services/ax-helpers.service';
import { AxProjectsService } from './services/ax-projects.service';
import { AxContactsService } from './services/ax-contacts.service';
import { AxDocumentsService } from './services/ax-documents.service';
import { AxMailsV2Service } from './services/ax-mails-v-2.service';
import { AxGoogleCalendarApiService } from './services/ax-google-calendar-api.service';
import { AxInteractionsV2Service } from './services/ax-interactions-v-2.service';
import { AxReservationsService } from './services/ax-reservations.service';
import { AxReservationtypesService } from './services/ax-reservationtypes.service';
import { AxLocationsService } from './services/ax-locations.service';
import { AxAbsencesService } from './services/ax-absences.service';
import { AxAppDevicesService } from './services/ax-app-devices.service';
import { AxPushNotificationTokensService } from './services/ax-push-notification-tokens.service';
import { AxAudiencesService } from './services/ax-audiences.service';
import { AxAuthenticationService } from './services/ax-authentication.service';
import { AxOnetimepasswordsV2Service } from './services/ax-onetimepasswords-v-2.service';
import { AxCategoriesService } from './services/ax-categories.service';
import { AxCommentsService } from './services/ax-comments.service';
import { AxNewsService } from './services/ax-news.service';
import { AxDocumentsubtypesService } from './services/ax-documentsubtypes.service';
import { AxElectricalMeasurementsService } from './services/ax-electrical-measurements.service';
import { AxEventsService } from './services/ax-events.service';
import { AxEventInvitationsService } from './services/ax-event-invitations.service';
import { AxEventTypesService } from './services/ax-event-types.service';
import { AxEventRegistrationOptionsService } from './services/ax-event-registration-options.service';
import { AxEventInvitationtypesService } from './services/ax-event-invitationtypes.service';
import { AxFilesService } from './services/ax-files.service';
import { AxHealthService } from './services/ax-health.service';
import { AxItemsService } from './services/ax-items.service';
import { AxLoggingService } from './services/ax-logging.service';
import { AxMyService } from './services/ax-my.service';
import { AxProjectrevenuesService } from './services/ax-projectrevenues.service';
import { AxRoletypesService } from './services/ax-roletypes.service';
import { AxSearchService } from './services/ax-search.service';
import { AxStatusesService } from './services/ax-statuses.service';
import { AxTimerecorddetailsService } from './services/ax-timerecorddetails.service';
import { AxTimerecordtypesService } from './services/ax-timerecordtypes.service';
import { AxUploadsService } from './services/ax-uploads.service';
import { AxAbsencesV2Service } from './services/ax-absences-v-2.service';
import { AxActivityTypesV2Service } from './services/ax-activity-types-v-2.service';
import { AxAgentsV2Service } from './services/ax-agents-v-2.service';
import { AxAppDevicesV2Service } from './services/ax-app-devices-v-2.service';
import { AxArchiveboxesV2Service } from './services/ax-archiveboxes-v-2.service';
import { AxAudiencesV2Service } from './services/ax-audiences-v-2.service';
import { AxAuthenticationV2Service } from './services/ax-authentication-v-2.service';
import { AxBusinessdivisionsV2Service } from './services/ax-businessdivisions-v-2.service';
import { AxBusinessUnitsV2Service } from './services/ax-business-units-v-2.service';
import { AxCategoriesV2Service } from './services/ax-categories-v-2.service';
import { AxContactsV2Service } from './services/ax-contacts-v-2.service';
import { AxContainersV2Service } from './services/ax-containers-v-2.service';
import { AxCreditorsV2Service } from './services/ax-creditors-v-2.service';
import { AxDocumentsV2Service } from './services/ax-documents-v-2.service';
import { AxParashiftV2Service } from './services/ax-parashift-v-2.service';
import { AxUsersV2Service } from './services/ax-users-v-2.service';
import { AxDocumentsubtypesV2Service } from './services/ax-documentsubtypes-v-2.service';
import { AxDocumenttypesV2Service } from './services/ax-documenttypes-v-2.service';
import { AxEquipmentlogsV2Service } from './services/ax-equipmentlogs-v-2.service';
import { AxEquipmentlogtypesV2Service } from './services/ax-equipmentlogtypes-v-2.service';
import { AxEquipmentsV2Service } from './services/ax-equipments-v-2.service';
import { AxEquipmenttypesV2Service } from './services/ax-equipmenttypes-v-2.service';
import { AxEventInvitationsV2Service } from './services/ax-event-invitations-v-2.service';
import { AxEventsV2Service } from './services/ax-events-v-2.service';
import { AxItemsV2Service } from './services/ax-items-v-2.service';
import { AxLeadCategoriesV2Service } from './services/ax-lead-categories-v-2.service';
import { AxLeadSubCategoriesV2Service } from './services/ax-lead-sub-categories-v-2.service';
import { AxLocationsV2Service } from './services/ax-locations-v-2.service';
import { AxMailtypesV2Service } from './services/ax-mailtypes-v-2.service';
import { AxNewsV2Service } from './services/ax-news-v-2.service';
import { AxOutcometypesV2Service } from './services/ax-outcometypes-v-2.service';
import { AxPermissionsV2Service } from './services/ax-permissions-v-2.service';
import { AxPhonetypesV2Service } from './services/ax-phonetypes-v-2.service';
import { AxReportsV2Service } from './services/ax-reports-v-2.service';
import { AxTimerecordBalancesV2Service } from './services/ax-timerecord-balances-v-2.service';
import { AxRoletypesV2Service } from './services/ax-roletypes-v-2.service';
import { AxSearchV2Service } from './services/ax-search-v-2.service';
import { AxStandardtasksV2Service } from './services/ax-standardtasks-v-2.service';
import { AxStatusV2Service } from './services/ax-status-v-2.service';
import { AxStocklocationsV2Service } from './services/ax-stocklocations-v-2.service';
import { AxTagsV2Service } from './services/ax-tags-v-2.service';
import { AxTasksV2Service } from './services/ax-tasks-v-2.service';
import { AxTasktypesV2Service } from './services/ax-tasktypes-v-2.service';
import { AxTeammembershipsV2Service } from './services/ax-teammemberships-v-2.service';
import { AxTeamsV2Service } from './services/ax-teams-v-2.service';
import { AxTimerecorddetailsV2Service } from './services/ax-timerecorddetails-v-2.service';
import { AxTimerecordHolidaysV2Service } from './services/ax-timerecord-holidays-v-2.service';
import { AxTimerecordsV2Service } from './services/ax-timerecords-v-2.service';
import { AxTimerecordtypesV2Service } from './services/ax-timerecordtypes-v-2.service';
import { AxTitlesV2Service } from './services/ax-titles-v-2.service';
import { AxTrainingtypesV2Service } from './services/ax-trainingtypes-v-2.service';
import { AxUserconfigurationsV2Service } from './services/ax-userconfigurations-v-2.service';
import { AxUsertrainingsV2Service } from './services/ax-usertrainings-v-2.service';
import { AxWebsiteGoogleReviewsV2Service } from './services/ax-website-google-reviews-v-2.service';
import { AxWebsiteStatisticsV2Service } from './services/ax-website-statistics-v-2.service';
import { AxWebsiteEmployeesV2Service } from './services/ax-website-employees-v-2.service';
import { AxWebsiteArticlesAndBannersV2Service } from './services/ax-website-articles-and-banners-v-2.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AxApiService,
    AxUseremploymentsV2Service,
    AxMontageplanV2Service,
    AxGerustbauV2Service,
    AxElektroDrittkontrolleV2Service,
    AxDocumentsGeneratorService,
    AxUsersService,
    AxPhonesV2Service,
    AxNotificationsV2Service,
    AxHelpersService,
    AxProjectsService,
    AxContactsService,
    AxDocumentsService,
    AxMailsV2Service,
    AxGoogleCalendarApiService,
    AxInteractionsV2Service,
    AxReservationsService,
    AxReservationtypesService,
    AxLocationsService,
    AxAbsencesService,
    AxAppDevicesService,
    AxPushNotificationTokensService,
    AxAudiencesService,
    AxAuthenticationService,
    AxOnetimepasswordsV2Service,
    AxCategoriesService,
    AxCommentsService,
    AxNewsService,
    AxDocumentsubtypesService,
    AxElectricalMeasurementsService,
    AxEventsService,
    AxEventInvitationsService,
    AxEventTypesService,
    AxEventRegistrationOptionsService,
    AxEventInvitationtypesService,
    AxFilesService,
    AxHealthService,
    AxItemsService,
    AxLoggingService,
    AxMyService,
    AxProjectrevenuesService,
    AxRoletypesService,
    AxSearchService,
    AxStatusesService,
    AxTimerecorddetailsService,
    AxTimerecordtypesService,
    AxUploadsService,
    AxAbsencesV2Service,
    AxActivityTypesV2Service,
    AxAgentsV2Service,
    AxAppDevicesV2Service,
    AxArchiveboxesV2Service,
    AxAudiencesV2Service,
    AxAuthenticationV2Service,
    AxBusinessdivisionsV2Service,
    AxBusinessUnitsV2Service,
    AxCategoriesV2Service,
    AxContactsV2Service,
    AxContainersV2Service,
    AxCreditorsV2Service,
    AxDocumentsV2Service,
    AxParashiftV2Service,
    AxUsersV2Service,
    AxDocumentsubtypesV2Service,
    AxDocumenttypesV2Service,
    AxEquipmentlogsV2Service,
    AxEquipmentlogtypesV2Service,
    AxEquipmentsV2Service,
    AxEquipmenttypesV2Service,
    AxEventInvitationsV2Service,
    AxEventsV2Service,
    AxItemsV2Service,
    AxLeadCategoriesV2Service,
    AxLeadSubCategoriesV2Service,
    AxLocationsV2Service,
    AxMailtypesV2Service,
    AxNewsV2Service,
    AxOutcometypesV2Service,
    AxPermissionsV2Service,
    AxPhonetypesV2Service,
    AxReportsV2Service,
    AxTimerecordBalancesV2Service,
    AxRoletypesV2Service,
    AxSearchV2Service,
    AxStandardtasksV2Service,
    AxStatusV2Service,
    AxStocklocationsV2Service,
    AxTagsV2Service,
    AxTasksV2Service,
    AxTasktypesV2Service,
    AxTeammembershipsV2Service,
    AxTeamsV2Service,
    AxTimerecorddetailsV2Service,
    AxTimerecordHolidaysV2Service,
    AxTimerecordsV2Service,
    AxTimerecordtypesV2Service,
    AxTitlesV2Service,
    AxTrainingtypesV2Service,
    AxUserconfigurationsV2Service,
    AxUsertrainingsV2Service,
    AxWebsiteGoogleReviewsV2Service,
    AxWebsiteStatisticsV2Service,
    AxWebsiteEmployeesV2Service,
    AxWebsiteArticlesAndBannersV2Service,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
