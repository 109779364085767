
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateTagDtoFormGroup() {
return new FormGroup({
    name: new FormControl<string | undefined>('', [Validators.required]),
    technicalName: new FormControl<string | undefined>('', [Validators.required]),
})
;
}
      