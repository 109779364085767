import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AxApiJsonInterceptor implements HttpInterceptor {
  constructor() {
  }

  /**
   * Intercept every HTTP request and to the following:
   * - add Bearer token for authentication
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf(environment.apiConfiguration.baseUrl) > -1) {
      return next.handle(req).pipe(
        tap(httpResponse => {
          if (httpResponse instanceof HttpResponse) {
            try {
              LoggerService.LOG(this, 'intercept()', JSON.parse(httpResponse.body));
            } catch (jsonParseException) {
              LoggerService.LOG(this, 'intercept()', httpResponse.body);
            }
          }
          return httpResponse;
        }),
      );
    }
    return next.handle(req);
  }
}
